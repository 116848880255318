import * as styles from './TeamCard.module.css';

import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import React from 'react';

const TeamCard = (props) => {
  const classes = [styles.TeamCard];

  const { link, img } = props;
  if (link !== undefined) {
    classes.push(styles.Hover);
  }

  return (
    <Link
      to={props.link !== undefined ? props.link : null}
      className={classes.join(' ')}>
      <div className={styles.Filter}>
        {typeof img === 'string' ? (
          <img src={props.img} alt={props.name} />
        ) : (
          <GatsbyImage image={img} loading='eager' />
        )}
        <div className={styles.Nametag}>
          <p className={styles.Name}>{props.name}</p>
          <p>{props.title}</p>
        </div>
      </div>
    </Link>
  );
};

export default TeamCard;
