import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import CardGrid from '../../containers/CardGrid/CardGrid';
import DefaultImage from '../../images/Default-Preview.png';
import Layout from '../../containers/layout';
import TeamCard from '../../components/Card/TeamCard';
import { getImage } from 'gatsby-plugin-image';
import { getUrl } from '../../util';

const Team = ({ location }) => {
  const data = useStaticQuery(graphql`
    query teamPageQuery {
      allStrapiTeamMembers {
        nodes {
          id
          HasBioPage
          Name
          Title
          Email
          Content
          MeetingLink
          Picture {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1, placeholder: BLURRED)
              }
            }
          }
        }
      }
      allSitePage {
        nodes {
          path
          context {
            id
          }
        }
      }
      strapiStaticPages(Title: { eq: "Team" }) {
        Title
        SEO {
          MetaTitle
          MetaDescription
          Keywords
          PreviewImage {
            formats {
              small {
                url
                width
                height
              }
            }
          }
        }
      }
    }
  `);

  const seoData = data.strapiStaticPages.SEO;

  const teamMemberArr = data.allStrapiTeamMembers.nodes;
  const urlArr = data.allSitePage.nodes;

  //sort team cards by last name alphabetically
  teamMemberArr.sort((a, b) => {
    const splitNameA = a.Name.split(' ');
    const splitNameB = b.Name.split(' ');
    const lastNameA = splitNameA[splitNameA.length - 1].toUpperCase();
    const lastNameB = splitNameB[splitNameB.length - 1].toUpperCase();

    if (lastNameA < lastNameB) {
      return -1;
    }
    if (lastNameA > lastNameB) {
      return 1;
    }

    return 0;
  });

  const teamNodes = teamMemberArr.map((member, i) => {
    const fullName = member.Name.replace(/\s+/g, ' ').trim();
    const nameParts = fullName.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts[1];
    const imageUrl = `https://d2t1ydnqrsm3ke.cloudfront.net/patriotic-team/${firstName}-${lastName}-Patriotic-500px.png`;
    const image = member.Picture && member.Picture.localFile ? getImage(member.Picture.localFile) : DefaultImage;
  
    return (
      <TeamCard
        key={i}
        name={fullName}
        title={member.Title}
        link={getUrl(urlArr, member.id)}
        // This switcher makes pictures patriotic
        //img={imageUrl ? imageUrl : image}
        img={image}
      />
    );
  });

  // const [show, setShow] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShow(true);
  //   }, 800);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  return (
    <Layout seoData={seoData} location={location}>
      <h1>Meet the Team</h1>
      <CardGrid show maxWidth={1440}>
        {teamNodes}
      </CardGrid>
    </Layout>
  );
};

export default Team;
